import React from 'react';
import './App.css';
import {Box} from "@mui/joy";
import TopMenuBar from "./TopMenuBar";
import MainScroll from "./MainScroll";

export default function App() {
    return <Box sx={{width: 1}}>
        <TopMenuBar/>
        <MainScroll/>
    </Box>
}
