import {Box, Button, IconButton, Typography} from "@mui/joy";
import React from "react";

export default function TopMenuBar() {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', width: 1, height: 70, backgroundOpacity: 70}}>
            <Box sx={{maxWidth: 'lg', display: 'flex', width: 1, alignItems: 'center', px: 2}}>
                <Typography>caleneer</Typography>
                <Button
                    variant="plain"
                    sx={{ml: 'auto', mr: 1 / 2, display: {xs: 'none', sm: 'inline'}}}
                    disabled
                    onClick={() => window.open("#features", "_self")}
                >
                    Features
                </Button>
                <Button
                    variant="plain"
                    sx={{mr: 1 / 2, display: {xs: 'none', sm: 'inline'}}}
                    onClick={() => window.open("https://docs.caleneer.com", "_self")}
                >
                    Documentation
                </Button>
                <Button
                    sx={{mx: 1 / 2, display: {xs: 'none', sm: 'inline'}}}
                    variant="plain"
                    onClick={() => window.open("https://caleneer.canny.io", "_self")}
                >
                    Roadmap
                </Button>
                <Button
                    sx={{mx: 1 / 2, display: {xs: 'none', md: 'inline'}}}
                    disabled
                    onClick={() => window.open("https://app.caleneer.com", "_self")}
                >
                    Get Started
                </Button>
            </Box>
        </Box>
    )
}