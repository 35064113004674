import {Box, Button, LinearProgress, Typography} from "@mui/joy";
import React from "react";

export default function HeroLanding() {
    return (
        <Box sx={{maxWidth: 'lg', display: 'flex', width: 1, alignItems: 'center', px: 2, flexDirection: 'column'}}>
            <Typography level={'h1'}>Caleneer</Typography>
            <Typography level={'h4'}>Commandeer your Calendar</Typography>
            <Box sx={{mt: 6}}>
                <Typography level={'body-lg'}>Coming soon...</Typography>
                <LinearProgress/>
            </Box>
            <Button
                size={'lg'}
                sx={{mt: 4}}
                disabled
                onClick={() => window.open("https://app.caleneer.com", "_self")}
            >
                Get Started
            </Button>
        </Box>
    )
}